import {
  Company,
  EnrichedKpiGroupDto,
  SlimFocusAreaDto,
} from '@thegist-for-wix/api';
import { FC } from 'react';

import {
  CentralizedEffortCommandItem,
  CompanyCommandItem,
  KpiGroupCommandItem,
} from './';

import { Item, ItemType, OnSelectType } from '../types';

interface KpiGroupCommandItemProps {
  type: ItemType;
  item: Item;
  onSelect: OnSelectType;
}
export const CommandItem: FC<KpiGroupCommandItemProps> = ({
  type,
  item,
  onSelect,
}) => {
  switch (type) {
    case 'company':
      const company = item as Company;
      return <CompanyCommandItem company={company} onSelect={onSelect} />;
    case 'focus-area':
      const focusArea = item as SlimFocusAreaDto;
      return (
        <CentralizedEffortCommandItem
          focusArea={focusArea}
          onSelect={onSelect}
        />
      );
    case 'kpi-group':
      const kpiGroup = item as EnrichedKpiGroupDto;
      return <KpiGroupCommandItem kpiGroup={kpiGroup} onSelect={onSelect} />;
  }
};

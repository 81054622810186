import {
  Company,
  EnrichedKpiGroupDto,
  SlimFocusAreaDto,
} from '@thegist-for-wix/api';
import { Command } from 'cmdk';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

import {
  CentralizedEffortCommandItem,
  CommandItem,
  CompanyCommandItem,
  KpiGroupCommandItem,
} from './CommandItems';
import { Footer } from './Footer/Footer';
import { Queue } from './Queue';
import styles from './style.module.scss';
import { ItemType, OnSelectType, QueueDataType } from './types';

import { useApi } from '@/api';
import SearchLg from '@/icons/components/SearchLg';

export const CommandBar = () => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const [value, setValue] = useState('');
  const queueRef = useRef<Queue<QueueDataType>>(new Queue());

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      const isSlash = e.key === '/' && (e.metaKey || e.ctrlKey);

      if (isSlash || e.key === 'Escape') {
        e.preventDefault();
        setOpen((open) => !open);
        setValue('');
        return setTimeout(() => inputRef?.current?.focus(), 100);
      }

      if (e.key === 'Enter') {
        setOpen(false);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const { results } = useApi(async (api) => {
    const res = await api.bffSearchBarControllerGetSearchBarData();
    return res;
  }, []);

  const kpiGroups = results?.kpiGroups;
  const centralizedEfforts = results?.centralizedEfforts;
  const companies = results?.companies;

  const onSelect: OnSelectType = (
    type: ItemType,
    item: Company | SlimFocusAreaDto | EnrichedKpiGroupDto,
  ) => {
    queueRef.current.enqueue({ type, item });
    setValue('');
    setOpen(false);
    switch (type) {
      case 'company':
        const company = item as Company;
        router.push(`/companies/${company.id}`);
        break;
      case 'focus-area':
        const focusArea = item as SlimFocusAreaDto;
        router.push(`/centralized-efforts/${focusArea.id}`);
        break;
      case 'kpi-group':
        const kpiGroup = item as EnrichedKpiGroupDto;
        router.push(`/kpi-groups/${kpiGroup.kpiGroup.id}`);
        break;
    }
  };

  return (
    <>
      {open && (
        <div className={styles.backdrop} onClick={() => setOpen(false)} />
      )}
      <div
        className={styles.commandMenuWrapper}
        style={{ display: open ? 'block' : 'none' }}
      >
        <div className={styles.commandMenuInnerWrapper}>
          <Command
            value={value}
            onValueChange={setValue}
            loop={true}
            label="Command Menu"
          >
            <div className={styles.inputWrapper}>
              <SearchLg className={styles.searchIcon} />
              <Command.Input placeholder="Search..." ref={inputRef} />
              <kbd>⌘+/</kbd>
            </div>
            <Command.List>
              <Command.Empty>No results found.</Command.Empty>

              {queueRef.current.size() > 0 && (
                <Command.Group heading="Recent">
                  {queueRef.current
                    .getItems()
                    ?.map((queueItem: QueueDataType, i) => {
                      return (
                        <CommandItem
                          key={i}
                          {...queueItem}
                          onSelect={onSelect}
                        />
                      );
                    })}
                </Command.Group>
              )}

              <Command.Group heading="Kpi Group">
                {kpiGroups?.map((kpiGroup: EnrichedKpiGroupDto) => (
                  <KpiGroupCommandItem
                    key={kpiGroup.kpiGroup.id}
                    kpiGroup={kpiGroup}
                    onSelect={onSelect}
                  />
                ))}
              </Command.Group>

              <Command.Group heading="Centralized Efforts">
                {centralizedEfforts?.map((focusArea: SlimFocusAreaDto, i) => (
                  <CentralizedEffortCommandItem
                    key={i}
                    focusArea={focusArea}
                    onSelect={onSelect}
                  />
                ))}
              </Command.Group>

              <Command.Group heading="Companies">
                {companies?.map((company) => (
                  <CompanyCommandItem
                    key={company.id}
                    company={company}
                    onSelect={onSelect}
                  />
                ))}
              </Command.Group>
            </Command.List>
            <Footer />
          </Command>
        </div>
      </div>
    </>
  );
};

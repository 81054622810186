import styles from './style.module.scss';

import ArrowDown from '@/icons/components/ArrowDown';
import ArrowUp from '@/icons/components/ArrowUp';
import CornerDownLeft from '@/icons/components/CornerDownLeft';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.arrowBox}>
        <ArrowUp />
      </div>
      <div className={styles.arrowBox}>
        <ArrowDown />
      </div>
      to navigate
      <div className={styles.arrowBox}>
        <CornerDownLeft />
      </div>
      to select
      <div className={styles.arrowBoxEsc}>esc</div>
      to close
    </footer>
  );
};

import { useEffect } from 'react';

export const useTitle = (title = '') => {
  useEffect(() => {
    if (title.trim() !== '') {
      document.title = `${title} - TheGist`;
    } else {
      document.title = `TheGist`;
    }
  }, [title]);
};

import { EnrichedKpiGroupDto } from '@thegist-for-wix/api';
import { Command } from 'cmdk';
import { FC, useId } from 'react';

import { OnSelectType } from '../types';

import LineChartUp from '@/icons/components/LineChartUp';

interface KpiGroupCommandItemProps {
  kpiGroup: EnrichedKpiGroupDto;
  onSelect: OnSelectType;
}
export const KpiGroupCommandItem: FC<KpiGroupCommandItemProps> = ({
  kpiGroup,
  onSelect,
}) => {
  const id = useId();
  return (
    <Command.Item
      value={kpiGroup.kpiGroup.name + id}
      onSelect={() => onSelect('kpi-group', kpiGroup)}
    >
      <LineChartUp />
      {kpiGroup.kpiGroup.name}
    </Command.Item>
  );
};

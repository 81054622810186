import { Company } from '@thegist-for-wix/api';
import { Command } from 'cmdk';
import { FC, useId } from 'react';

import styles from '../style.module.scss';
import { OnSelectType } from '../types';

interface CompanyCommandItemProps {
  company: Company;
  onSelect: OnSelectType;
}
export const CompanyCommandItem: FC<CompanyCommandItemProps> = ({
  company,
  onSelect,
}) => {
  const id = useId();

  return (
    <Command.Item
      value={company.name + id}
      onSelect={() => onSelect('company', company)}
    >
      <div
        className={styles.companyOwner}
        style={{ backgroundImage: `url(${company.owner?.avatarUrl})` }}
      />
      {company.name}
    </Command.Item>
  );
};

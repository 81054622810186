'use client';

import { ResponseError } from '@thegist-for-wix/api';
import { useRouter } from 'next/navigation';
import { FC, useEffect, useState } from 'react';

import styles from './page.module.css';

import { CommandBar } from '../_components/CommandBar/CommandBar';
import { Drawer } from '../_components/Drawer/Drawer';
import { SupportProvider } from '../_components/Support/SupportProvider';
import { ToastProvider } from '../_components/Toast/ToastProvider';
import Providers from '../_providers/Providers';

import { useBackendApi } from '@/api';
import Breadcrumbs from '@/app/_components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbProvider } from '@/context/breadcrumb';
import { DataTypesProvider } from '@/context/dataTypes';
import { ModalProvider } from '@/context/modal';

interface ProtectedLayoutProps {
  children: React.ReactNode;
}

const ProtectedLayout: FC<Readonly<ProtectedLayoutProps>> = ({ children }) => {
  const router = useRouter();
  const api = useBackendApi();
  const [authed, setAuthed] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await api.authControllerPing();
        setAuthed(true);
      } catch (e) {
        if (e instanceof ResponseError) {
          if ((e as ResponseError).response.status === 401) {
            console.error('Not authed');
            router.push('/login');
            return;
          } else if ((e as ResponseError).response.status === 403) {
            router.push('/request-access');
            return;
          }
        } else {
          // not an API error
        }

        throw e;
      }
    })();
  }, [router, api]);

  return (
    <Providers
      providers={[
        ToastProvider,
        DataTypesProvider,
        ModalProvider,
        SupportProvider,
        BreadcrumbProvider,
      ]}
    >
      {authed && (
        <div className={styles.container}>
          <Drawer />
          <CommandBar />
          <div className={styles.main}>
            <Breadcrumbs />
            <div className={styles.content} id="main-content">
              {children}
            </div>
          </div>
        </div>
      )}
    </Providers>
  );
};

export default ProtectedLayout;

import { SlimFocusAreaDto } from '@thegist-for-wix/api';
import { Command } from 'cmdk';
import { FC, useId } from 'react';

import { OnSelectType } from '../types';

import Calendar from '@/icons/components/Calendar';

interface CentralizedEffortCommandItemProps {
  focusArea: SlimFocusAreaDto;
  onSelect: OnSelectType;
}
export const CentralizedEffortCommandItem: FC<
  CentralizedEffortCommandItemProps
> = ({ focusArea, onSelect }) => {
  const id = useId();

  return (
    <Command.Item
      value={focusArea.name + id}
      onSelect={() => onSelect('focus-area', focusArea)}
    >
      <Calendar />
      {focusArea.name}
    </Command.Item>
  );
};

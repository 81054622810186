'use client';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { useParams, usePathname } from 'next/navigation';
import { createContext, useContext, useEffect, useState } from 'react';

import { iProviderProps } from '@/app/_providers/Providers';

type iBreadcrumbContext = {
  isDynamic: boolean;
  dynamicPageName: string;
  setDynamicPageName: (name?: string) => void;
};
const BreadcrumbContext = createContext<iBreadcrumbContext>({
  setDynamicPageName: noop,
  isDynamic: false,
  dynamicPageName: '',
});

export const useBreadcrumb = () => useContext(BreadcrumbContext);

export const BreadcrumbProvider = ({ children }: iProviderProps) => {
  const r = useParams();
  const isDynamic = !isEmpty(r);
  const [dynamicPageName, setDynamicPageName] = useState('');
  const location = usePathname();

  useEffect(() => {
    setDynamicPageName('');
  }, [location]);

  return (
    <BreadcrumbContext.Provider
      value={{
        setDynamicPageName: (value) => setDynamicPageName(value || ''),
        isDynamic,
        dynamicPageName,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

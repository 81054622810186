const storageKey = 'recent-command';

export class Queue<T> {
  private items: T[] = [];
  private capacity: number;

  constructor() {
    this.capacity = 2;
    this.items = [];
    this.loadFromLocalStorage();
  }

  enqueue = (item: T): void => {
    if (this.isFull()) {
      this.items.shift();
    }
    this.items.push(item);
    this.saveToLocalStorage();
  };

  dequeue = (): T | undefined => this.items.shift();
  peek = (): T | undefined => this.items[0];
  getItems = (): T[] | undefined => this.items;
  isEmpty = (): boolean => this.items.length === 0;
  isFull = (): boolean => this.items.length === this.capacity;
  size = (): number => this.items.length;

  loadFromLocalStorage = (): void => {
    try {
      const data = localStorage.getItem(storageKey);
      if (data) {
        this.items = JSON.parse(data);
      }
    } catch (error) {
      console.warn('Error loading queue from local storage:', error);
    }
  };

  saveToLocalStorage = (): void =>
    localStorage.setItem(storageKey, JSON.stringify(this.items));
}

import { ComponentType, ReactNode } from 'react';

// export
export interface BaseProviderProps {
  children: ReactNode;
}

export type iProviderProps<ServerProps = undefined> =
  ServerProps extends undefined
    ? BaseProviderProps
    : BaseProviderProps & { serverProps: ServerProps };

function ComposeProviders({
  providers = [],
  children,
}: {
  children: ReactNode;
  providers: ComponentType<Readonly<iProviderProps>>[];
}) {
  return (
    <>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
}

export default ComposeProviders;

import { ReactNode } from 'react';
import { IntercomProvider } from 'react-use-intercom';

interface Props {
  children?: ReactNode;
}

export const SupportProvider = ({ children }: Props) => {
  const intercomId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
  if (!intercomId) {
    return children;
  }

  return <IntercomProvider appId={intercomId}>{children}</IntercomProvider>;
};
